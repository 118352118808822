import React from 'react';

import Link from 'next/link';
import routes from '../../server/routes';
import styled from 'styled-components';

import {
  NewButton,
  NewButtonSizes,
  NewButtonTypes,
} from '../../components/Toolkit/NewButton/NewButton';
import { BASE_URL, CDN_URL_STATIC_DIRECTORY } from '../../helpers/utils';
import { DefaultLayout } from '../../layouts/DefaultLayout';

type ErrorPageProps = {
  pageVariant?: string;
  homepageBtn?: boolean;
  errorMessage?: string;
  errorCode?: string | number;
};

const ErrorWrapper = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  padding: 40px 20px;
  min-height: 70vh;
  @media only screen and (min-width: 1040px) {
    padding: 100px 0;
  }
`;

const ErrorCode = styled.p`
  ${({ theme }) => theme.fontSize.S14};
  font-weight: ${({ theme }) => theme.fontWeight.BOLD};
  color: ${({ theme }) => theme.color.GREY_DARK};
  margin-bottom: ${({ theme }) => theme.spacing.S12};
  @media only screen and (min-width: 640px) {
    ${({ theme }) => theme.fontSize.S16};
    margin-bottom: ${({ theme }) => theme.spacing.M16};
  }
`;

const ErrorMessage = styled.p`
  max-width: 754px;
  color: ${({ theme }) => theme.color.GREY_DARK};
  font-weight: ${({ theme }) => theme.fontWeight.REGULAR};
  ${({ theme }) => theme.fontSize.T28}
  margin-bottom: ${({ theme }) => theme.spacing.M20};
  @media only screen and (min-width: 640px) {
    font-size: 56px;
    ${({ theme }) => theme.fontSize.H44};
  }
`;

const ErrorPage = (props: ErrorPageProps) => {
  const {
    errorCode,
    errorMessage = errorCode === 403
      ? 'This user does not have access'
      : 'Sorry, it looks like something has gone wrong.',
    homepageBtn = true,
    pageVariant,
  } = props;

  return (
    <DefaultLayout
      pageVariant={pageVariant || undefined}
      BASE_URL={BASE_URL}
      CDN_URL_STATIC_DIRECTORY={CDN_URL_STATIC_DIRECTORY}
      routes={routes}
    >
      <ErrorWrapper>
        <ErrorCode data-testid="errorPage-statusCode">{errorCode}</ErrorCode>
        <ErrorMessage data-testid="errorPage-message">
          {errorMessage}
        </ErrorMessage>
        {homepageBtn && (
          <Link
            passHref
            href={errorCode === 403 ? '/auth/logout' : '/'}
            legacyBehavior
          >
            <NewButton
              buttonText={errorCode === 403 ? 'Logout' : 'Back to Homepage'}
              buttonType={NewButtonTypes.PRIMARY}
              buttonSize={NewButtonSizes.SMALL}
              href="#"
              data-testid="errorPage-backToHomeBtn"
            />
          </Link>
        )}
      </ErrorWrapper>
    </DefaultLayout>
  );
};

export default ErrorPage;
