import React from 'react';

import ErrorPage from '../components/ErrorPage/ErrorPage';

import { NextContext } from '../types';

type ErrorPageProps = {
  statusCode?: number;
  pageVariant?: string;
  message?: string;
  homepageBtn?: boolean;
};

const Error = (props: ErrorPageProps) => {
  const { pageVariant, message, homepageBtn = true, statusCode } = props;
  return (
    <ErrorPage
      errorCode={statusCode ? statusCode : 'No error code'}
      errorMessage={message}
      homepageBtn={homepageBtn}
      pageVariant={pageVariant}
    />
  );
};

export default Error;

Error.getInitialProps = ({ res, err }: NextContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};
