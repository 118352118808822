import styled from 'styled-components';

import { DelayedSpinner } from '../../Toolkit/Spinner/DelayedSpinner';

export const FIRST_BREAK_POINT = '898px';

export const MainFlexWrapper = styled.div`
  ${({ isLoading }: { isLoading: boolean }) => isLoading && 'opacity: 0.15'};
  max-width: 1040px;
  margin: 0 auto;
  padding: 15px 0px 0px;

  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    display: flex;
    padding: 36px 8px 0px;
  }

  @media only screen and (min-width: 1056px) {
    padding: 36px 0px 0px;
  }
`;

export const CenteredSpinner = styled(DelayedSpinner)`
  position: fixed;
  right: calc(50% - 30px);
  top: 52%;
  width: 60px;
  z-index: 999;
`;

export const SearchResultsHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 16px 24px;
  width: calc(100% - 32px);

  @media only screen and (min-width: 704px) {
    margin-top: 0;
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 900px) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;

export const SearchPageListViewResultsHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px 24px 0px;
  @media only screen and (min-width: 704px) {
    flex-wrap: nowrap;
    width: 100%;
  }
`;

export const SearchH1 = styled.h1`
  width: 100%;
  font-size: 12px;
  color: #333333;
  font-weight: 700;
  @media only screen and (min-width: 704px) {
    width: 90%;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
`;
